import algoliasearch from 'algoliasearch'
import dayjs from 'dayjs'
import firebase from 'firebase/app'
import { isEmpty } from 'lodash'
import Repository from 'repositories/Repository'
import { generateFacetFilters, generateNumericFilters } from 'utils/Algolia'
import FirebaseManager from 'utils/FirebaseManager'
import Joker from 'models/Joker'
import type JokerCondition from 'models/JokerCondition'
import type {
  OfferListSortTypeForAce,
  OfferSortType,
  Query,
  SubJokerConditionValues,
} from 'types'

class JokerRepository extends Repository {
  private static _find_by_all_limit = 100
  private readonly collection

  constructor() {
    super()

    this.collection = this.mainFirestore.collection('jokers')

    this.findById = this.findById.bind(this)
    this.findByFacebookUserId = this.findByFacebookUserId.bind(this)
    this.create = this.create.bind(this)
    this.update = this.update.bind(this)
    this.uploadJokerProfileImage = this.uploadJokerProfileImage.bind(this)
  }

  findById(jokerId: Joker['id']): Promise<Joker | null> {
    return new Promise((resolve, reject) => {
      this.collection
        .doc(jokerId)
        .get({ source: 'server' })
        .then((doc) => {
          if (!doc.exists) {
            // TODO: エラー返したほうがよいなら返すようにする
            resolve(null)
            return
          }
          const joker = new Joker({ id: doc.id, ...doc.data() })
          resolve(joker)
          return
        })
        .catch((error) => {
          reject(error)
          return
        })
    })
  }

  findByIds(jokerIds: Joker['id'][]): Promise<Joker[]> {
    return new Promise((resolve, reject) => {
      this.collection
        .where(firebase.firestore.FieldPath.documentId(), 'in', jokerIds)
        .get({ source: 'server' })
        .then((docs) => {
          const jokers: Joker[] = []
          docs.forEach((doc) => {
            const joker = new Joker({ id: doc.id, ...doc.data() })
            jokers.push(joker)
          })
          resolve(jokers)
          return
        })
        .catch((error) => {
          reject(error)
          return
        })
    })
  }

  findByFacebookUserId(facebookUserId: string) {
    return new Promise((resolve, reject) => {
      this.collection
        .where('facebookUserId', '==', facebookUserId)
        .limit(1)
        .get({ source: 'server' })
        .then((result) => {
          if (!result.empty && !isEmpty(result.docs)) {
            resolve(new Joker(result.docs[0]?.data()))
            return
          } else {
            resolve(null)
            return
          }
        })
        .catch((error) => {
          reject(error)
          return
        })
    })
  }

  select(jokerIds: Joker['id'][]): Promise<Joker[]> {
    const jokers: any = []
    const tasks: any = []
    jokerIds.forEach((jokerId) => {
      if (isEmpty(jokerId)) {
        return
      }
      const task = this.collection.doc(jokerId).get({ source: 'server' })
      tasks.push(task)
    })

    return new Promise((resolve, reject) => {
      return Promise.all(tasks)
        .then((results) => {
          results.forEach((doc) => {
            const jokerId = doc.id
            const joker = new Joker({ id: jokerId, ...doc.data() })
            jokers.push(joker)
          })
          resolve(jokers)
          return
        })
        .catch((error) => {
          reject(error)
          return
        })
    })
  }

  async search(
    jokerCondition?: Omit<JokerCondition, 'toJS'> | null,
    sortType?: OfferSortType,
    nextPage?: number | null,
  ) {
    const appId = process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID
    const apiKey = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY

    if (!appId || !apiKey) {
      throw new Error()
    }

    const sort = this.getSortType(sortType)
    const keyword = jokerCondition?.freeWords?.join(' ') || ''

    const client = algoliasearch(appId, apiKey)
    const index = client.initIndex(`${sort}_desc`)

    index.setSettings({
      attributesForFaceting: [
        'registrationStatus',
        'occupation',
        'subOccupation',
        'preferredWorkplaces',
        'interestedCompanyScales',
        'jobChangeWillingness',
        'sideJobWillingness',
        'hasIntroduction',
        'birthdayTimestamp',
      ],
      // MEMO: オファー送付用の検索以外の用途が必要になったら外から渡せるようにする
      unretrievableAttributes: ['name', 'nameKana', 'email'],
    })

    const facetFilters = generateFacetFilters({
      registrationStatus: 'registered',
      occupations: jokerCondition?.occupations,
      subOccupations: jokerCondition?.subOccupations,
      preferredWorkplaces: jokerCondition?.preferredWorkplaces,
      companyScales: jokerCondition?.companyScales,
      jobChangeWillingnesses: jokerCondition?.jobChangeWillingnesses,
      sideJobWillingnesses: jokerCondition?.sideJobWillingnesses,
      hasIntroduction: jokerCondition?.hasIntroduction,
    })

    const numericFilters = generateNumericFilters({
      minAge: jokerCondition?.minAge,
      maxAge: jokerCondition?.maxAge,
    })

    const result = await index.search(keyword, {
      facetFilters,
      numericFilters,
      page: nextPage || 0,
    })

    const jokers = result.hits.map(
      (data: any) =>
        new Joker({
          ...data,
          onlineAt: dayjs.unix(data.onlineAt).toDate(),
          offlineAt: dayjs.unix(data.offlineAt).toDate(),
          createdAt: dayjs.unix(data.createdAt).toDate(),
          updatedAt: dayjs.unix(data.updatedAt).toDate(),
        }),
    )

    return {
      jokers,
      totalCounts: result.nbHits,
      page: result.page,
      totalPages: result.nbPages,
    }
  }

  async findAll(
    jokerCondition?: Omit<JokerCondition, 'toJS'> | null,
    subConditionValues?: SubJokerConditionValues | null,
    nextQuery?: Query | null,
  ): Promise<{
    jokers: Joker[]
    nextQuery: Query | null
  }> {
    const targetSkillNames = subConditionValues?.skillNames || []
    const targetIndustries = jokerCondition?.industries || []
    const targetSubOccupations = (jokerCondition?.subOccupations || []).slice(
      0,
      10,
    )
    const targetMinAge = jokerCondition?.minAge || 0
    const targetMaxAge = jokerCondition?.maxAge || 100
    const targetJobChangeWillingnesses =
      jokerCondition?.jobChangeWillingnesses || []
    const targetSideJobWillingnesses =
      jokerCondition?.sideJobWillingnesses || []

    let query: Query

    if (nextQuery) {
      query = nextQuery
    } else {
      if (targetSkillNames.length > 0) {
        query = this.collection.where(
          'skills',
          'array-contains-any',
          targetSkillNames,
        )
      } else if (targetIndustries.length > 0) {
        query = this.collection.where('industry', 'in', targetIndustries)
      } else if (targetSubOccupations.length > 0) {
        query = this.collection.where(
          'subOccupation',
          'in',
          targetSubOccupations,
        )
      } else if (targetJobChangeWillingnesses.length > 0) {
        query = this.collection.where(
          'jobChangeWillingness',
          'in',
          targetJobChangeWillingnesses,
        )
      } else if (targetSideJobWillingnesses.length > 0) {
        query = this.collection.where(
          'sideJobWillingness',
          'in',
          targetSideJobWillingnesses,
        )
      } else {
        query = this.collection
      }

      if (subConditionValues?.sortType === 'recentLogin') {
        query = query.orderBy('onlineAt', 'desc')
      } else {
        query = query.where('score', '>=', 0).orderBy('score', 'desc')
      }
    }

    const snapshot = await query.limit(JokerRepository._find_by_all_limit).get()
    const results: Joker[] = snapshot.docs
      .map((doc) => new Joker({ id: doc.id, ...doc.data() }))
      .filter((joker): joker is Joker => {
        const isNotTargetAge =
          joker.age < targetMinAge || targetMaxAge < joker.age
        const isNotIncludedIndustry =
          targetIndustries.length > 0 &&
          !targetIndustries.includes((joker.industry as any) || 'none')
        const isNotIncludedSubOccupation =
          targetSubOccupations.length > 0 &&
          !targetSubOccupations.includes(joker.subOccupation || 'none')
        const isNotIncludedJCW =
          targetJobChangeWillingnesses.length > 0 &&
          !targetJobChangeWillingnesses.includes(
            (joker.jobChangeWillingness as any) || 'none',
          )
        const isNotIncludedSJW =
          targetSideJobWillingnesses.length > 0 &&
          !targetSideJobWillingnesses.includes(
            (joker.sideJobWillingness as any) || 'none',
          )
        if (
          isNotTargetAge ||
          isNotIncludedIndustry ||
          isNotIncludedSubOccupation ||
          isNotIncludedJCW ||
          isNotIncludedSJW
        ) {
          return false
        }
        return true
      })
    if (snapshot.docs.length < JokerRepository._find_by_all_limit) {
      return { jokers: results, nextQuery: null }
    }
    let newNextQuery = query
    const lastDoc = snapshot.docs[snapshot.docs.length - 1]
    if (lastDoc) {
      newNextQuery = newNextQuery.startAfter(lastDoc)
    }

    return { jokers: results, nextQuery: newNextQuery }
  }

  create(joker: Joker) {
    const jokerData = joker.toJS()
    const jokerId = jokerData.id
    delete jokerData.id
    return this.collection.doc(jokerId as any).set(jokerData)
  }

  update(jokerId: Joker['id'], data: any) {
    return this.collection.doc(jokerId).update(data)
  }

  /**
   * Firebase storage へのアップロード処理
   */
  uploadJokerProfileImage(file: any, jokerId: Joker['id']) {
    return new Promise((resolve, reject) => {
      const fileExtension = file.name.split('.').pop()
      if (!fileExtension) {
        reject(
          new Error(
            'ファイルの拡張子が取得できませんでした。開発者にお問い合わせください。',
          ),
        )
        return
      }

      const directoryPath = `jokers/profileImage/${jokerId}/`
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const uploadTask = FirebaseManager.storageRef
        .child(`${directoryPath}/profile.${fileExtension}`)
        .put(file)

      uploadTask.on(
        'state_changed',
        (snapshot: any) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
        (error: any) => {
          reject(error)
          return
        },
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL: any) => {
              resolve(downloadURL)
              return
            })
            .catch((error: any) => {
              reject(error)
              return
            })
        },
      )
    })
  }

  private getSortType(sortType?: OfferListSortTypeForAce) {
    if (!sortType) {
      return 'score'
    }

    switch (sortType) {
      case 'recommended':
        return 'score'
      case 'recentLogin':
        return 'onlineAt'
      default: {
        const unreachable: never = sortType
        break
      }
    }
  }
}

const jokerRepository = new JokerRepository()

Object.freeze(jokerRepository)

export default jokerRepository
