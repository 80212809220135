import { type FC, useState } from 'react'
import type { ImageProps } from 'next/image'
import Image from 'next/image'
import styled from 'styled-components'
import { TRANSITION_DURATION } from 'styles/variables'

export type NextImageProps = ImageProps & {
  disableAnimation?: boolean
}

export const NextStyledImage = styled(Image)`
  opacity: 0;
  transition: opacity ${TRANSITION_DURATION};

  &[data-loaded='done'] {
    opacity: 1;
  }
`

export const NextImage: FC<NextImageProps> = ({
  disableAnimation,
  ...restProps
}) => {
  const [loaded, setLoaded] = useState(false)

  if (disableAnimation) {
    return (
      <Image
        {...restProps}
        alt={restProps.alt}
      />
    )
  }

  return (
    <NextStyledImage
      data-loaded={loaded ? 'done' : ''}
      onLoadingComplete={() => setLoaded(true)}
      {...restProps}
    />
  )
}

NextImage.displayName = 'NextImage'
