export default class Model {
  public toJS(isIncludeId = false): { [key: string]: any } {
    const data: { [key: string]: any } = Object.assign({}, this)
    Object.keys(data).forEach(
      (key) => data[key] === undefined && delete data[key],
    )
    if (!isIncludeId) {
      delete data.id
    }
    return data
  }
}
