import { Record } from 'immutable'
import moment from 'moment'

const AceRecord = Record({
  id: '',
  companyId: '',
  articleId: '',
  companyName: '',
  name: '',
  nameKana: '',
  gender: '',
  birthday: '',
  profileImageURL: '',
  email: '',
  occupations: [],
  position: '',
  business: '',
  businessURL: '',
  businessDetail: '',
  graduatedSchool: '',
  graduatedFacultyDepartment: '',
  careers: [],
  introduction: '',
  valueInJob: '',
  appealOfWorking: '',
  registrationStatus: '',
  isAgent: false,
  isAvailable: false,
  favoriteUserIds: [],
  createdAt: '',
  updatedAt: '',
  expireAt: '',
  personalities: [],
})

class Ace extends AceRecord {
  get age() {
    const currentMoment = moment()
    const birthdayMoment = moment(this.birthday)
    return currentMoment.diff(birthdayMoment, 'years')
  }

  get japaneseGender() {
    return this.gender === 'male'
      ? '男性'
      : this.gender === 'female'
      ? '女性'
      : this.gender === 'other'
      ? 'その他'
      : ''
  }

  get japaneseBirthday() {
    const birthdayMoment = moment(this.birthday)
    return birthdayMoment.isValid()
      ? birthdayMoment.format('YYYY年MM月DD日')
      : '- 年 - 月 - 日'
  }
}

export default Ace
