import { isEmpty } from 'lodash'
import Repository from 'repositories/Repository'
import AppError, { errorCodes } from 'utils/AppError'
import FirebaseManager from 'utils/FirebaseManager'
import Ace from 'models/Ace'

class AceRepository extends Repository {
  constructor() {
    super()

    this.collection = this.mainFirestore.collection('aces')

    this.findById = this.findById.bind(this)
    this.findByCompanyId = this.findByCompanyId.bind(this)
  }

  findById(aceId) {
    return new Promise((resolve, reject) => {
      const companyId = localStorage.getItem('cid')
      if (isEmpty(companyId)) {
        reject(new Error('No company id'))
        return
      }

      this.collection
        .where('companyId', '==', companyId)
        .get({ source: 'server' })
        .then((docs) => {
          let result = null
          docs.forEach((doc) => {
            if (doc.id === aceId) {
              result = new Ace({ id: doc.id, ...doc.data() })
              return
            }
          })
          resolve(result)
          return
        })
        .catch((error) => {
          reject(error)
          return
        })
    })
  }

  findByCompanyId(companyId) {
    const result = []
    return new Promise((resolve, reject) => {
      this.collection
        .where('companyId', '==', companyId)
        .get({ source: 'server' })
        .then((docs) => {
          docs.forEach((doc) => {
            const ace = new Ace({ id: doc.id, ...doc.data() })
            result.push(ace)
          })
          resolve(result)
          return
        })
        .catch((error) => {
          reject(error)
          return
        })
    })
  }

  create(aceId, aceData) {
    const query = !isEmpty(aceId)
      ? this.collection.doc(aceId)
      : this.collection.doc()
    return query.set(aceData)
  }

  update(aceId, aceData) {
    if (!aceId || !aceData) {
      return Promise.reject(new AppError(errorCodes.invalidArgument))
    }
    return this.collection.doc(aceId).update(aceData)
  }

  updateFavoriteUserId(aceId, jokerId, isDelete) {
    if (!aceId || !jokerId) {
      return Promise.reject(new AppError(errorCodes.invalidArgument))
    }
    const fieldValue = isDelete
      ? this.fieldValue.arrayRemove(jokerId)
      : this.fieldValue.arrayUnion(jokerId)
    return this.collection.doc(aceId).update({
      favoriteUserIds: fieldValue,
    })
  }

  /**
   * Firebase storage へのアップロード処理
   * @param {アプロード対象のファイル} file
   */
  uploadAceProfileImage(file, companyId, aceId) {
    return new Promise((resolve, reject) => {
      const fileExtension = file.name.split('.').pop()
      if (!fileExtension) {
        reject(
          new Error(
            'ファイルの拡張子が取得できませんでした。開発者にお問い合わせください。',
          ),
        )
        return
      }

      const directoryPath = `companies/${companyId}/profileImages/${aceId}`
      const uploadTask = FirebaseManager.mainStorageRef
        .child(`${directoryPath}/profile.${fileExtension}`)
        .put(file)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
        (error) => {
          reject(error)
          return
        },
        () => {
          resolve(uploadTask.snapshot.ref)
          return
        },
      )
    })
  }
}

const aceRepository = new AceRepository()

Object.freeze(aceRepository)

export default aceRepository
