import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { v4 } from 'uuid'
import Repository from 'repositories/Repository'
import type Ace from 'models/Ace'
import type Company from 'models/Company'
import type Joker from 'models/Joker'
import Matching from 'models/Matching'

class MatchingRepository extends Repository {
  private readonly collection

  constructor() {
    super()
    this.collection = this.mainFirestore.collection('matchings')
  }

  findById(matchingId: Matching['id']) {
    return new Promise((resolve, reject) => {
      const companyId = localStorage.getItem('cid')
      if (isEmpty(companyId)) {
        reject(new Error('No company id'))
        return
      }

      this.collection
        .doc(matchingId)
        .get({ source: 'server' })
        .then((doc) => {
          if (!doc.exists) {
            resolve(null)
            return
          }
          const matching = new Matching({ id: doc.id, ...doc.data() })
          resolve(matching)
          return
        })
        .catch((error) => {
          // 存在しないマッチングの場合, permission-denied が返ってくる
          // TODO: バックエンド側でマッチングは論理削除にして
          // エラーが出ないようにするか, firestore.rules を変えたほうがいいかも...
          if (error.code === 'permission-denied') {
            resolve(null)
            return
          }
          reject(error)
          return
        })
    })
  }

  findByCompanyId(companyId: Matching['aceCompanyId']) {
    const result: Matching[] = []
    return this.collection
      .where('aceCompanyId', '==', companyId)
      .orderBy('matchedAt', 'desc')
      .get({ source: 'server' })
      .then((docs) => {
        docs.forEach((doc) => {
          const matching = new Matching({ id: doc.id, ...doc.data() })
          result.push(matching)
        })
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  select(matchingIds: Matching['id'][]) {
    const matchings: Matching[] = []
    const tasks: Promise<unknown>[] = []

    if (!matchingIds || matchingIds.length === 0) {
      return Promise.resolve([])
    }

    matchingIds.forEach((matchingId) => {
      if (isEmpty(matchingId)) {
        return
      }
      const task = new Promise((resolve, reject) => {
        this.collection
          .doc(matchingId)
          .get({ source: 'server' })
          .then((doc) => {
            resolve(doc)
          })
          .catch((error) => {
            if (error.code === 'permission-denied') {
              resolve(null)
              return
            }
            reject(error)
          })
      })
      tasks.push(task)
    })

    return new Promise((resolve, reject) => {
      return Promise.all(tasks)
        .then((results) => {
          // FIXME: any -> DocumentReference 型に変更する
          results.forEach((doc: any) => {
            if (!doc) {
              return
            }
            const matchingId = doc.id
            const matching = new Matching({ id: matchingId, ...doc.data() })
            matchings.push(matching)
          })
          resolve(matchings)
          return
        })
        .catch((error) => {
          reject(error)
          return
        })
    })
  }

  /**
   * HACK:
   * - 当処理は、usecase に相当する層に委譲するべき
   * - ただ、matching テーブルは破棄し、chat テーブルに移行する予定
   * - 移行を実施するなら委譲は不要
   *
   * TODO:
   * - company リポジトリのアーキテクチャ見直し
   */
  async forceMatching(joker: Joker, ace: Ace): Promise<Matching> {
    if (!this.isForcedMatchingAllowed(ace)) {
      throw new Error(`Name: ${ace.name} ace is not allowed forced matching.`)
    }

    const matching = await this.findByJokerAndAceId(joker.id, ace.id)
    if (matching) {
      return matching
    }

    // HACK: 以下の data create 処理は model に相当する層に配置されるべき
    const newMatchingData = new Matching({
      id: v4(),
      aceCompanyId: ace.companyId,
      aceId: ace.id,
      operatorId: 'operator',
      jokerId: joker.id,
      matchedAt: new Date(),
    })
    const savedMatching = await this.save(newMatchingData)
    return savedMatching
  }

  // HACK: 以下は domainService に相当する層に配置されるべき
  async isForcedMatchingAllowed(ace: Ace) {
    const companyId = ace.companyId
    if (!ace.isAgent || !companyId) {
      return false
    }
    // TODO: company リポジトリから取得するように変更する
    const company = await this.mainFirestore.doc(`companies/${companyId}`).get()
    if (!company.exists || !(company.data() as Company).isOperator) {
      return false
    }
    return true
  }

  // MEMO: 以下は repository に相当する層に配置されるべき
  private async save(matching: Matching): Promise<Matching> {
    const { id: matchingId } = matching
    const docRef = this.collection.doc(matchingId)
    const doc = await docRef.get()
    if (
      doc.exists &&
      !dayjs(matching.lastMessagedAtDayjs).isSame(doc.data()?.matchedAt)
    ) {
      // TODO: ドキュメントの作成、更新日時を評価するフィールドは createdAt, updatedAt で統一したい
      throw new Error("Can't update matchedAt field")
    }
    await docRef.set(matching.toJS(), { merge: true })
    const savedMatching = await this.findById(matchingId)
    // HACK: 一致する id を持つ doc が必ず存在するため、null を排除できる
    return savedMatching as Matching
  }

  // MEMO: 以下は repository に相当する層に配置されるべき
  private async findByJokerAndAceId(
    jokerId: Matching['jokerId'],
    aceId: Matching['aceId'],
  ): Promise<Matching | null> {
    const querySnapshots = await this.collection
      .where('jokerId', '==', jokerId)
      .where('aceId', '==', aceId)
      .get()

    if (querySnapshots.empty) {
      return null
    }
    if (querySnapshots.size > 1) {
      throw new Error(`
        Multiple matching found.
        - jokerId: ${jokerId}
        - aceId: ${aceId}
      `)
    }
    // HACK: querySnapshot.empty が false なので、null を排除できる
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const doc = querySnapshots.docs[0]!
    return new Matching({ id: doc.id, ...doc.data() })
  }
}

const matchingRepository = new MatchingRepository()

Object.freeze(matchingRepository)

export default matchingRepository
