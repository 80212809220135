import dayjs from 'dayjs'
import firebase from 'firebase/app'
import { isEmpty } from 'lodash'
import { P, match } from 'ts-pattern'
import * as FieldValue from 'constants/FieldValue'
import type Career from 'models/Career'
import Model from 'models/model'
import type {
  CompanyScaleType,
  FocusOnChoosingCompanyType,
  IndustryType,
  JobChangeWillingnessType,
  JokerPersonality,
  JokerRegistrationStatusType,
  ManagementExperienceType,
  OccupationType,
  Rank,
  SideJobWillingnessType,
  SubOccupationType,
  WorkStyleType,
} from 'types'

export default class Joker extends Model {
  id?: string
  likeId?: string
  name?: string
  nameKana?: string
  gender?: string
  birthday?: string
  profileImageURL?: string
  email?: string
  graduatedSchool?: string
  graduatedFacultyDepartment?: string
  careers?: Career[]
  jobChangeWillingness?: JobChangeWillingnessType
  sideJobWillingness?: SideJobWillingnessType
  occupation?: OccupationType
  subOccupation?: SubOccupationType | null
  industry?: IndustryType
  preferredWorkplaces?: string[] | null
  appealPoint?: string | null
  portfolioURL?: string | null
  introduction?: string
  skills?: string[] | null
  englishLevel?: string | null
  englishScores?: { [key: string]: string | number } | null
  managementExperience?: ManagementExperienceType | null
  interestedOccupations?: OccupationType[]
  interestedCompanyScales?: CompanyScaleType[]
  workStyles?: WorkStyleType[] | null
  focusOnChoosingCompanies?: FocusOnChoosingCompanyType[] | null
  minDesiredSalary?: number | null
  maxDesiredSalary?: number | null
  favoriteUserIds?: string[] | null
  isInstalledApp?: boolean
  fcmToken?: string
  facebookUserId?: string | null
  registeredOn?: string | null
  registrationStatus?: JokerRegistrationStatusType
  rank?: Rank | null
  hiddenCompanyDomains?: string[]
  personalities?: JokerPersonality[]
  onlineAt?: firebase.firestore.Timestamp | firebase.firestore.FieldValue | Date
  offlineAt?:
    | firebase.firestore.Timestamp
    | firebase.firestore.FieldValue
    | Date
  createdAt?:
    | firebase.firestore.Timestamp
    | firebase.firestore.FieldValue
    | Date
  updatedAt?:
    | firebase.firestore.Timestamp
    | firebase.firestore.FieldValue
    | Date
  firstRegisteredAt?:
    | firebase.firestore.Timestamp
    | firebase.firestore.FieldValue
    | Date

  static COLLECTION_NAME = 'jokers'

  static REGISTRATION_STATUSES = {
    LOGGED_IN: 'loggedIn',
    PROVISIONING: 'provisioning',
    JUDGING: 'judging',
    REGISTERED: 'registered',
  }

  static KEY_NAMES = {
    id: 'ID',
    name: '名前',
    nameKana: 'カナ',
    gender: '性別',
    birthday: '生年月日',
    profileImage: 'プロフィール画像',
    profileImageURL: 'プロフィール画像URL',
    email: 'メールアドレス',
    careers: '職歴',
    salary: '現在の年収',
    graduatedSchool: '学校名',
    graduatedFacultyDepartment: '学部/学科',
    values: '価値観',
    jobChangeWillingness: '転職意欲',
    sideJobWillingness: '副業意欲',
    occupation: '職種',
    industry: '業種',
    portfolioURL: 'ポートフォリオURL',
    wantTodo: 'やってみたいこと',
    appealPoint: 'アピールポイント',
    preferredWorkplaces: '希望勤務地',
    interestedOccupations: '今の職種以外で興味のある職種',
    interestedCompanyScales: '興味のある企業規模',
    englishLevel: '英語力',
    englishScores: '英語スコア',
    createdAt: '作成日',
    updatedAt: '更新日',
    registrationStatus: '登録状況',
    rank: 'ランク',
    personalities: 'もっと私',
  }
  constructor(init?: Partial<Joker>) {
    super()
    const defaultValues = {
      name: '',
      nameKana: '',
      gender: '',
      birthday: '',
      profileImageURL: '',
      email: '',
      careers: [],
      graduatedSchool: '',
      graduatedFacultyDepartment: '',
      jobChangeWillingness: '',
      occupation: '',
      subOccupation: '',
      industry: '',
      introduction: '',
      interestedOccupations: [],
      interestedCompanyScales: [],
      createdAt: new Date(),
      updatedAt: new Date(),
      registrationStatus: '',
      personalities: [],
    }
    Object.assign(this, defaultValues)
    Object.assign(this, init)
  }

  get age() {
    const now = dayjs()
    const birthdayDayjs = dayjs(this.birthday)
    if (!birthdayDayjs.isValid()) {
      return 0
    }
    return now.diff(birthdayDayjs, 'year')
  }

  private get onlineAtDayjs() {
    const onlineAt = this.onlineAt || this.updatedAt
    if (!onlineAt) {
      return null
    }
    return match(onlineAt)
      .with(P.instanceOf(firebase.firestore.Timestamp), (onlineAt) => {
        const _dayjs = dayjs(onlineAt.toDate())
        return _dayjs.isValid() ? _dayjs : null
      })
      .with(P.instanceOf(Date), (onlineAt) => {
        const _dayjs = dayjs(onlineAt)
        return _dayjs.isValid() ? _dayjs : null
      })
      .otherwise(() => null)
  }

  /**
   * 最終ログインからの経過時間（時間単位）\
   * e.g. 以下の場合は、1.5 を返す
   * - 現在時刻:  2024/01/01 12:00:00
   * - onlineAt: 2024/01/01 10:30:00
   */
  get elapsedHoursFromLastLogin() {
    if (!this.onlineAtDayjs) {
      return null
    }
    const now = dayjs()
    //HACK: diff(now, 'hour')だと、現在時刻との差分が 1.5時間の場合、1時間と切り捨てられてしまうため
    return -this.onlineAtDayjs.diff(now, 'minute') / 60
  }

  /**
   * 最終ログインからの経過日数\
   * e.g. 以下の場合は、1.5 を返す
   * - 現在時刻:  2024/01/01 12:00:00
   * - onlineAt: 2023/12/31 0:00:00
   */
  get elapsedDaysFromLastLogin() {
    if (!this.onlineAtDayjs) {
      return null
    }
    const now = dayjs()
    //HACK: diff(now, 'day')だと、現在時刻との差分が 1.5日の場合、1日と切り捨てられてしまうため
    return -this.onlineAtDayjs.diff(now, 'hour') / 24
  }

  get japaneseGender() {
    return this.gender === 'male'
      ? '男性'
      : this.gender === 'female'
      ? '女性'
      : 'その他'
  }

  get japaneseBirthday() {
    const birthday = dayjs(this.birthday)
    return birthday.isValid()
      ? birthday.format('YYYY年MM月DD日')
      : '- 年 - 月 - 日'
  }

  get industryName() {
    if (isEmpty(this.industry)) {
      return ''
    }
    const targetItem = FieldValue.INDUSTRIES.find(
      (item) => item.value === this.industry,
    )
    return (targetItem && targetItem.name) || ''
  }

  get occupationName() {
    if (isEmpty(this.occupation)) {
      return ''
    }
    const targetItem = FieldValue.OCCUPATIONS.find(
      (item) => item.value === this.occupation,
    )
    return (targetItem && targetItem.name) || ''
  }

  get subOccupationName() {
    if (isEmpty(this.subOccupation)) {
      return ''
    }
    const targetItem = FieldValue.SUB_OCCUPATIONS.find(
      (item) => item.value === this.subOccupation,
    )
    return (targetItem && targetItem.name) || ''
  }

  get jobChangeWillingnessName() {
    if (isEmpty(this.jobChangeWillingness)) {
      return ''
    }
    const targetItem = FieldValue.JOB_CHANGE_WILLINGNESSES.find(
      (item) => item.value === this.jobChangeWillingness,
    )
    return (targetItem && targetItem.name) || ''
  }

  get sideJobWillingnessName() {
    if (isEmpty(this.sideJobWillingness)) {
      return ''
    }
    const targetItem = FieldValue.SIDE_JOB_WILLINGNESSES.find(
      (item) => item.value === this.sideJobWillingness,
    )
    return (targetItem && targetItem.name) || ''
  }

  get managementExperienceName() {
    if (isEmpty(this.managementExperience)) {
      return ''
    }
    const targetItem = FieldValue.MANAGEMENT_EXPERIENCES.find(
      (item) => item.value === this.managementExperience,
    )
    return (targetItem && targetItem.name) || ''
  }

  get preferredWorkplaceNames() {
    const itemNames: string[] = []
    if (!this.preferredWorkplaces) {
      return itemNames
    }
    this.preferredWorkplaces.forEach((value) => {
      if (isEmpty(value)) {
        return
      }
      const targetItem = FieldValue.PREFERRED_WORKPLACES.find(
        (item) => item.value === value,
      )
      if (isEmpty(targetItem) || isEmpty(targetItem?.name)) {
        return
      }
      targetItem && itemNames.push(targetItem.name)
    })
    return itemNames
  }

  get interestedOccupationNames() {
    const itemNames: string[] = []
    if (!this.interestedOccupations || !this.interestedOccupations.length) {
      return itemNames
    }
    this.interestedOccupations.forEach((value) => {
      if (isEmpty(value)) {
        return
      }
      const targetItem = FieldValue.OCCUPATIONS.find(
        (item) => item.value === value,
      )
      if (isEmpty(targetItem) || isEmpty(targetItem?.name)) {
        return
      }
      targetItem && itemNames.push(targetItem.name)
    })
    return itemNames
  }

  get interestedCompanyScaleNames() {
    const itemNames: string[] = []
    if (!this.interestedCompanyScales || !this.interestedCompanyScales.length) {
      return itemNames
    }
    this.interestedCompanyScales.forEach((value) => {
      if (isEmpty(value)) {
        return
      }
      const targetItem = FieldValue.COMPANY_SCALES.find(
        (item) => item.value === value,
      )
      if (isEmpty(targetItem) || isEmpty(targetItem?.name)) {
        return
      }
      targetItem && itemNames.push(targetItem.name)
    })
    return itemNames
  }

  get focusOnChoosingCompanyNames() {
    const itemNames: string[] = [] // HACK: Webではこの判定がなくても動く
    if (
      !this.focusOnChoosingCompanies ||
      !this.focusOnChoosingCompanies.length
    ) {
      return itemNames
    }
    this.focusOnChoosingCompanies.forEach((value) => {
      if (isEmpty(value)) {
        return
      }
      const targetItem = FieldValue.FOCUS_ON_CHOOSING_COMPANIES.find(
        (item) => item.value === value,
      )
      if (isEmpty(targetItem) || isEmpty(targetItem?.name)) {
        return
      }
      targetItem && itemNames.push(targetItem.name)
    })
    return itemNames
  }

  get workStyleNames() {
    const itemNames: string[] = []
    if (!this.workStyles || !this.workStyles.length) {
      return itemNames
    }
    this.workStyles.forEach((value) => {
      if (isEmpty(value)) {
        return
      }
      const targetItem = FieldValue.WORK_STYLES.find(
        (item) => item.value === value,
      )
      if (isEmpty(targetItem) || isEmpty(targetItem?.name)) {
        return
      }
      targetItem && itemNames.push(targetItem.name)
    })
    return itemNames
  }

  get desiredSalary() {
    const minDesiredSalaryString = !this.minDesiredSalary
      ? '希望なし'
      : `${this.minDesiredSalary}万円`
    const maxDesiredSalaryString = !this.maxDesiredSalary
      ? '希望なし'
      : `${this.maxDesiredSalary}万円`
    return `${minDesiredSalaryString} 〜 ${maxDesiredSalaryString}`
  }

  get isSwipeTarget() {
    if (!this.rank) {
      return
    }
    return (
      this.registrationStatus === 'registered' &&
      ['S', 'A', 'B', 'C', 'D', 'E'].includes(this.rank)
    )
  }

  get englishLevelString() {
    if (isEmpty(this.englishLevel)) {
      return ''
    }
    const level = FieldValue.ENGLISH_LEVELS.find(
      (level) => level.value === this.englishLevel,
    )
    if (!level) {
      return ''
    }
    return level.name || ''
  }

  get englishScoreString() {
    if (isEmpty(this.englishScores)) return ''
    const toeicAcqYear = this.englishScores?.toeicAcqYear
    const toeicScore = this.englishScores?.toeicScore
    const toeflAcqYear = this.englishScores?.toeflAcqYear
    const toeflScore = this.englishScores?.toeflScore

    let result = ''
    if (toeicScore && toeicAcqYear) {
      result += `TOEIC: ${toeicScore}点 (${toeicAcqYear})`
    }
    if (toeflScore && toeflAcqYear) {
      if (result) {
        result += '\n'
      }
      result += `TOEFL: ${toeflScore}点 (${toeflAcqYear})`
    }
    return result
  }

  get mostRecentCompanyName() {
    if (!this.careers || this.careers.length === 0) {
      return ' - '
    }
    const mostRecentCareer =
      this.careers.find((career) => career.isMostRecent) || this.careers[0]
    return mostRecentCareer?.companyName || ' - '
  }

  get mostRecentBusiness() {
    if (!this.careers || this.careers.length === 0) {
      return ''
    }
    const mostRecentCareer =
      this.careers.find((career) => career.isMostRecent) || this.careers[0]
    return mostRecentCareer?.business
  }
}
