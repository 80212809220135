import dayjs from 'dayjs'
import type JokerCondition from 'models/JokerCondition'

type GenerateFacetFiltersParams = {
  registrationStatus: string
  occupations: JokerCondition['occupations']
  subOccupations: JokerCondition['subOccupations']
  preferredWorkplaces: JokerCondition['preferredWorkplaces']
  companyScales: JokerCondition['companyScales']
  jobChangeWillingnesses: JokerCondition['jobChangeWillingnesses']
  sideJobWillingnesses: JokerCondition['sideJobWillingnesses']
  hasIntroduction: JokerCondition['hasIntroduction']
}

type GenerateNumericFiltersParams = {
  minAge: JokerCondition['minAge']
  maxAge: JokerCondition['maxAge']
}

/**
 * 検索条件のフィルターの配列を生成
 * https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/
 */
export const generateFacetFilters = (params: GenerateFacetFiltersParams) => {
  const {
    registrationStatus,
    occupations,
    subOccupations,
    preferredWorkplaces,
    companyScales,
    jobChangeWillingnesses,
    sideJobWillingnesses,
    hasIntroduction,
  } = params

  const filters: Array<string[] | string> = []

  filters.push(`registrationStatus:${registrationStatus}`)

  if (occupations) {
    const query = occupations.map((v) => `occupation:${v}`)
    filters.push(query)
  }
  if (subOccupations) {
    const query = subOccupations.map((v) => `subOccupation:${v}`)
    filters.push(query)
  }
  if (preferredWorkplaces && preferredWorkplaces.length > 0) {
    const query = preferredWorkplaces.map((v) => `preferredWorkplaces:${v}`)
    filters.push(query)
  }
  if (companyScales && companyScales.length > 0) {
    const query = companyScales.map((v) => `interestedCompanyScales:${v}`)
    filters.push(query)
  }
  if (jobChangeWillingnesses) {
    const query = jobChangeWillingnesses.map((v) => `jobChangeWillingness:${v}`)
    filters.push(query)
  }
  if (sideJobWillingnesses) {
    const query = sideJobWillingnesses.map((v) => `sideJobWillingness:${v}`)
    filters.push(query)
  }
  if (hasIntroduction) {
    filters.push(`hasIntroduction:${hasIntroduction}`)
  }

  return filters
}

/**
 * 年齢のフィルターの配列を生成
 * https://www.algolia.com/doc/api-reference/api-parameters/numericFilters/
 */
export const generateNumericFilters = ({
  minAge,
  maxAge,
}: GenerateNumericFiltersParams) => {
  const filters: string[] = []

  if (minAge) {
    const timestamp = dayjs().subtract(minAge, 'year').unix()
    filters.push(`birthdayTimestamp <= ${timestamp}`)
  }
  if (maxAge) {
    const timestamp = dayjs().subtract(maxAge, 'year').unix()
    filters.push(`birthdayTimestamp >= ${timestamp}`)
  }

  return filters
}
